import React, { useEffect } from "react";
import { graphql } from "gatsby";

import Footer from "../components/Footer/Footer.jsx";
import CTABanner from "../components/CTABanner/CTABanner.jsx";
import { Seo } from "../components/SEO/Seo.jsx";
import MainMenu from "../components/MainMenu/MainMenu.jsx";

import CircleLightDotsImg from "../images/circle-light-with-dots.svg";
import SkewRectangleHeroImg from "../images/skewRectangleHero.png";

// import TestimonialSection from "../components/About/components/TestimonialSection";

import AOS from "aos";
import "aos/dist/aos.css";

const PortfolioSingle = ({ data }) => {
  const {
    headingPrimary,
    portfolioTitle,
    // startDate,
    country,
    whatWeDeliveredTextSecond,
    whatWeDeliveredTextFirst,
    whatProblemWeSolvedText,
    featuredImg,
    projectImgFirst,
    paragraphPrimary,
    // testimonialText,
    // testimonialAuthor,
    // testimonialCompany,
    // testimonialImage,
    backendTechnologies,
    frontendTechnologies,
    testingTechnologies,
    technologyStackDescription,
    statisticsDescription,
    statisticHours,
    statisticTeam,
    statisticTimeline,
    statisticMethodology,
  } = data.mdx.frontmatter;

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  return (
    <main>
      <Seo
        title={
          portfolioTitle + " | GammaSoft: Custom Software Development Company"
        }
        description={"Check out our project" + portfolioTitle}
      />
      <MainMenu />
      <section className="portfolio-single-section">
        <div
          className="portfolio-single-section-hero"
          style={{ backgroundImage: `url(${SkewRectangleHeroImg})` }}
        >
          <img src={CircleLightDotsImg} alt="" />
          <div className="portfolio-single-section-hero-content container">
            <h1
              className="heading-primary"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              {headingPrimary}
            </h1>
            <p
              className="paragraph-primary"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              {paragraphPrimary}
            </p>
            <div className="portfolio-info">
              {/* <span>
                Date: <span>{startDate}</span>
              </span> */}
              <span data-aos="fade-up" data-aos-delay="50">
                Country: <span>{country}</span>
              </span>
            </div>
          </div>
        </div>
        <div className="portfolio-single-section-featured-image container">
          <img
            src={featuredImg?.publicURL}
            alt=""
            data-aos="fade-up"
            data-aos-delay="250"
          />
        </div>
        <div className="what-we-delivered-section" data-aos="fade-up">
          <div className="what-we-delivered-section-content container">
            <h2 className="heading-secondary">What we delivered?</h2>
            <div className="what-we-delivered-section-content-wrapper">
              <div className="what-we-delivered-section-content-wrapper-left">
                <p className="paragraph-primary">{whatWeDeliveredTextFirst}</p>
              </div>
              <div className="what-we-delivered-section-content-wrapper-right">
                <p className="paragraph-primary">{whatWeDeliveredTextSecond}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="technology-stack-section" data-aos="fade-up">
          <div className="technology-stack-section-content container">
            <div className="technology-stack-section-content-left">
              <h2 className="heading-secondary">Technology stack</h2>
              <p className="paragraph-primary">{technologyStackDescription}</p>
            </div>
            <div className="technology-stack-section-content-right">
              <div className="technology-stack-section-content-right-wrapper">
                <div className="technology-stack-section-content-right-wrapper-item">
                  <h3 className="heading-third">Backend architecture</h3>
                  <ul className="technology-list">
                    {backendTechnologies?.map(tech => (
                      <li className="paragraph-primary">{tech}</li>
                    ))}
                  </ul>
                </div>
                <div className="technology-stack-section-content-right-wrapper-item">
                  <h3 className="heading-third">Frontend</h3>
                  <ul className="technology-list paragraph-primary">
                    {frontendTechnologies?.map(tech => (
                      <li className="paragraph-primary">{tech}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="what-problem-solved-section" data-aos="fade-up">
          <div className="what-problem-solved-section-content container">
            <h2 className="heading-secondary">
              What problem we <span>solved?</span>
            </h2>
            <p className="paragraph-primary">{whatProblemWeSolvedText}</p>
          </div>
        </div>
        <div className="statistics-section" data-aos="fade-up">
          <div className="statistics-section-content container">
            <h2 className="heading-secondary">Statistics</h2>
            <p className="statistics-section-content-text paragraph-primary">
              {statisticsDescription}
            </p>
            <div className="statistics-section-content-wrapper container">
              <div className="statistics-section-content-wrapper-item">
                <h3 className="heading-third">{statisticHours}</h3>
                <p className="paragraph-primary">Working hours</p>
              </div>
              <div className="statistics-section-content-wrapper-item">
                <h3 className="heading-third">{statisticTeam}</h3>
                <p className="paragraph-primary">Size of team</p>
              </div>
              <div className="statistics-section-content-wrapper-item">
                <h3 className="heading-third">{statisticTimeline}</h3>
                <p className="paragraph-primary">Timeline</p>
              </div>
              <div className="statistics-section-content-wrapper-item">
                <h3 className="heading-third">{statisticMethodology}</h3>
                <p className="paragraph-primary">Methodology</p>
              </div>
            </div>
          </div>
        </div>
        <div className="project-images">
          <div className="project-images-content container">
            <img src={projectImgFirst?.publicURL} alt="" />
          </div>
        </div>
        {/* <TestimonialSection
          testimonialText={testimonialText}
          testimonialAuthor={testimonialAuthor}
          testimonialCompany={testimonialCompany}
          testimonialImage={testimonialImage?.publicURL}
        /> */}
      </section>
      <CTABanner />
      <Footer />
    </main>
  );
};

export default PortfolioSingle;

export const query = graphql`
  query PortfolioSingle($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        headingPrimary
        portfolioTitle
        startDate
        country
        whatWeDeliveredTextFirst
        whatWeDeliveredTextSecond
        whatProblemWeSolvedText
        paragraphPrimary
        testimonialText
        testimonialAuthor
        testimonialCompany
        technologyStackDescription
        backendTechnologies
        frontendTechnologies
        statisticsDescription
        statisticHours
        statisticTeam
        statisticTimeline
        statisticMethodology
        testimonialImage {
          publicURL
        }
        featuredImg {
          publicURL
        }
      }
    }
  }
`;
